@import "config/grid.yaml";
@import "config/templates.yaml";
@import "~@nematis/wp-alpha/assets/sass/abstracts/yaml-variables";
@import "assets/sass/init";
@import "../init";

// === WP ALPHA ASSETS
@import "~@nematis/wp-alpha/assets/sass/pages/home";
@import "~@nematis/wp-alpha/assets/sass/pages/contact";
@import "~@nematis/wp-alpha/assets/sass/components/parallax";

body{
  .site-header .logo svg{
    max-height: 30vh;
  }

  @include media-breakpoint-down($desktop-breakpoint) {
    h1,
    .h1{
      @include font-size(3rem);
    }
  }
}

.home-parallax-row {
  // stylelint-disable-next-line
  min-height: Min(var(--asp-min-height), 100vh);
}

@include media-breakpoint-down(sm) {
  .home-contact-row {
    overflow-x: hidden;
  }
}

.home {
  .featured-image {
    @media (orientation: portrait) {
      .ratio {
        height: 100%;

        iframe {
          left: 50%;
          width: calc(var(--featured-image-height) / .5225);
          margin-left: calc(-1 * var(--featured-image-height) / .5225 / 2);
        }
      }
    }
  }
}

.home-logos-row {
  .logo {
    max-width: 150px;
  }
}

//.home-map {
//  .map-block-inner {
//    height: 100%;
//  }
//}

.featured-image-mobile-wrapper {
  background-image: url("../../images/home/andorre-la-vieille.jpg");
  background-size: cover;
}

.youtube-interview {
  iframe {
    height: 50vh;
    width: 100vw;
  }
}
