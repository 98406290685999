@import "~bootstrap/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/variables";

// === Website colors
$color-bondi-blue: #009eb5;
$color-bermuda: #7ccad8;

// === Bootstrap
// == Colors
$gray-100: #f7f7f7;
$black: #000;
$primary: $color-bondi-blue;
$secondary: $color-bermuda;
$body-color: $black;
$body-bg: $gray-100;

// == Grid
$desktop-breakpoint: xxl;

// == Typography
$font-family-sans-serif: "Roboto", sans-serif;
$font-size-base: 1rem;
$h1-font-size: 2.5rem; // 40px
$h2-font-size: 2rem; // 32px
$h3-font-size: 1.75rem; // 28px
$h4-font-size: 1.25rem; // 20px
$home-font-sizes: (
  "h1": 3.75rem,
  "h2": 2.5rem,
  "h3": 1.875rem,
  "h4": 1.5625
);

// == Accessibility
$min-contrast-ratio: 3;

// == Buttons
$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1rem;

// === CF7 Effect
$color-form-filled: $secondary;
$form-gutter: .8rem;
$form-label-translate: -1.2rem;
$form-label-gutter-top: .5rem;

// === Alpha
// == Configuration
$menus: ("center", "burger");
$teases: ("grid", "simple");

// == Dark theme
$dark-bg-color: $primary;

// == Featured image
$home-featured-image-height: calc(100vh - var(--adminbar-height));

// == Header
$header-bg: white;

// == Logo
$logo-height: 6.25rem;

// == Sidebar
$sidebar: true;

// == Simple parallax
$asp-overlay-bg-color: white;
$asp-overlay-bg-opacity: .35;

// === Fonts optimization
// == FontAwesome Icons
$custom-used-fa-icons: (
  // == Business
  balance-scale: $fa-var-balance-scale,
  building: $fa-var-building,
  sticky-note: $fa-var-sticky-note,
  // == Education
  file-certificate: $fa-var-file-certificate,
  // == Maps
  gavel: $fa-var-gavel,
  // == Mathematics
  minus: $fa-var-minus,
  // == People
  male: $fa-var-male,
  skiing: $fa-var-skiing,
  // == Shopping
  shopping-bag: $fa-var-shopping-bag,
  // == Social
  users: $fa-var-users,
);
